import React from 'react'
import styled from 'styled-components'
import Carousel from 're-carousel'
import isEmpty from 'lodash/isEmpty'
import delve from 'dlv'

import Buttons from './Buttons'

import media from 'utils/media'

const CarouselWrapper = styled.div`
	--carousel-height: 260px;
	--description-height: 32px;

	height: calc(var(--carousel-height) + var(--description-height));
	width: 340px;
	user-select: none;

	${media.md`
    --carousel-height: 330px
    width: 530px;
  `}
`

const CarouselStyled = styled(Carousel)`
	width: 340px;

	${media.md`
    width: 530px;
  `}

	img {
		margin: auto;
		display: block;
		border-radius: 25px;
		height: 260px;
		/* width: 340px; */

		${media.md`
      height: 330px;
      /* width: 530px; */
    `}
	}
`

const Description = styled.p`
	margin-top: 5px;

	font-size: 15px;
	font-weight: bold;
	line-height: 1.8;
	color: ${({ theme }) => theme.colors.gray};
	text-align: center;
`

const CarouselContainer = ({ images }) => {
	const getImgSrc = image => {
		if (delve(image, 'kraks[1].url')) {
			return image.kraks[1].url
		} else {
			return image.kraks[0].url
		}
	}

	return (
		!isEmpty(images) && (
			<CarouselWrapper>
				<CarouselStyled widgets={[Buttons]}>
					{images.map((image, i) => (
						<div key={`carousel-img-${i}`}>
							<img src={getImgSrc(image)} alt={`carousel-image-${i}`} key={`carousel-image-${i}`} />
							<Description>{image.description}</Description>
						</div>
					))}
				</CarouselStyled>
			</CarouselWrapper>
		)
	)
}

export default CarouselContainer
