import React from 'react'
import styled from 'styled-components'

import media from 'utils/media'

import { Column } from 'components/common/Grid'

const Section = styled.section`
  width: 100%;
  height: 100vh;
`

const Description = styled.p`
  width: 100%;

  font-size: 15px;
  line-height: 1.8;
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;

  margin: 30px auto;
`


export default function ShowRoom () {
  return (
    <Section>
      <Column>
        <Description>
          Welcome in ShowRoom. Get unique url from your friend to see more!
        </Description>
      </Column>
    </Section>
  )
}
