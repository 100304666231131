import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { getPublicShowroom } from 'components/common/GlobalState'
import { Container, Column, Row } from 'components/common/Grid'
import Button from 'components/common/Button'
import Carousel from 'components/common/Carousel'

import Heart from './Heart'
import Share from './Share'
import User from './User'

import media from 'utils/media'

const ContainerStyled = styled(Container)`
`

const ColumnStyled = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`

const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 45px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
`

const ItemDescription = styled.p`
  margin: 40px 0;

  font-family: WorkSans;
  font-size: 15px;  
  line-height: 1.87;
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;

  width: 360px;
  ${media.md`
    width: 530px;
  `}
`

const Title = styled.h2`
  font-family: QuickSand;
  font-size: 24;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  margin-bottom: 30px;
`

const Description = styled.p`
  width: 360px;
  ${media.md`
    width: 530px;
  `}

  font-size: 15px;
  line-height: 1.8;
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;

  margin: 30px 0;
`

const Socials = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 360px;

  ${media.md`
    width: 530px;
  `}
`

export default function ShowRoomPublic({ resykableGuid }) {
  const [showroom_data, set_showroom_data] = React.useState()

  React.useEffect(() => {
    getShowroomData()
  }, [])

  const getShowroomData = async () => {
    const data = await getPublicShowroom({ resykableGuid })
    set_showroom_data(data)
  }

  const first_item = showroom_data && {
    images: showroom_data.images,
    title: showroom_data.title,
    description: showroom_data.description
  }

  // TODO: where do other items come from
  const other_items = []

  // TODO where does avatar come from
  const user = showroom_data && {
    avatar: showroom_data.profileUri,
    name: showroom_data.userDisplayName
  }

  const [other_items_visible, set_other_items_visible] = React.useState()

  const viewOtherItems = () => set_other_items_visible(true)

  const isOnShowOrHasImages = showroom_data && !isEmpty(showroom_data.images)

  const onLikeClick = async () => {
    // TODO: do something when heart is clicked
  }

  const onShareClick = () => {}

  const Item = ({ item }) => (
    <ItemStyled>
      <Title>{item.title}</Title>
      <Carousel images={item.images} />
      <ItemDescription>
        {item.description}
      </ItemDescription>
      {/* <Socials>
        <Heart onClick={onLikeClick} />
        <Share onClick={onShareClick} />
      </Socials> */}
    </ItemStyled>
  )

  return (
    <ContainerStyled>
      {isOnShowOrHasImages ? (
        <>
          <Row>
            <Column>
              {user && <User user={user} viewOtherItems={viewOtherItems} />}
            </Column>
          </Row>
          <Row>
            <ColumnStyled>
              {first_item && <Item item={first_item} />}
              {other_items_visible && (
                <>
                  {other_items.map((item, i) => (
                    <Item key={`item-${i}`} item={item} />
                  ))}
                  {/* <Button primary>Start Resyk'ing Today</Button> */}
                </>
              )}
            </ColumnStyled>
          </Row>
        </>
      ) : (
        <Row>
          <Column>
            <Description style={{ width: '100%', margin: '30px auto' }}>
              The requested showroom is no longer on show or contains no public
              images.
            </Description>
          </Column>
        </Row>
      )}
    </ContainerStyled>
  )
}
