import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  font-family: QuickSand;
  font-size: 18px;
  font-weight: bold;
  color: #ff2749;

  cursor: pointer;
  user-select: none;
  &:hover > div {
    background-position: right;
  }
`

const Icon = styled.div`
  height: 50px;
  width: 80px;
  margin-right: -15px;
  background-image: url('https://abs.twimg.com/a/1446542199/img/t1/web_heart_animation.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;

  ${({ full }) =>
    full &&
    css`
      animation: heart-burst 0.8s steps(28) 1;
      background-position: right;
    `};

  &:hover {
  }

  @keyframes heart-burst {
    from {
      background-position: left;
    }
    to {
      background-position: right;
    }
  }
`

const Heart = props => {
  const [is_full, set_is_full] = React.useState()

  const onClick = e => {
    set_is_full(!is_full)
    props.onClick(e)
  }

  return (
    <Wrapper onClick={onClick}>
      <Icon full={is_full} /> Like
    </Wrapper>
  )
}

export default Heart
