import React from 'react'
import styled from 'styled-components'

import shareSvg from 'assets/svg/share.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  font-family: QuickSand;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;
  user-select: none;

  img {
    margin-right: 10px;
  }
`

const Share = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <img src={shareSvg} alt='share-button' /> Share
    </Wrapper>
  )
}

export default Share
