import React from 'react'
import styled, { css } from 'styled-components'

import arrowLeft from 'assets/svg/carousel-arrow-left.svg'
import arrowRight from 'assets/svg/carousel-arrow-right.svg'

const ButtonsContainer = styled.div`
  --size: 30px;

  position: absolute;
  top: calc(var(--carousel-height) / 2 - var(--size) / 2);
  width: 100%;
  z-index: 100;
`

const Button = styled.div`
  width: var(--size);
  height: var(--size);
  position: absolute;
  user-select: none;
  cursor: pointer;

  ${({ left }) =>
    left &&
    css`
      left: calc(0px - var(--size) / 2);
    `};

  ${({ right }) =>
    right &&
    css`
      right: calc(0px - var(--size) / 2);
    `};
`

const Buttons = props => {
  const { index, total, loop, prevHandler, nextHandler } = props

  return (
    <ButtonsContainer>
      {(loop || index !== 0) && (
        <Button left onClick={prevHandler}>
          <img src={arrowLeft} alt='arrow-left' />
        </Button>
      )}

      {(loop || index !== total - 1) && (
        <Button right onClick={nextHandler}>
          <img src={arrowRight} alt='arrow-right' />
        </Button>
      )}
    </ButtonsContainer>
  )
}

export default Buttons
