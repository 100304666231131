import React from 'react'
import { Router } from '@reach/router'

import Layout from 'components/common/Layout'
import ShowRoom from 'components/ShowRoom'
import ShowroomPublic from 'components/ShowroomPublic'

export default function Resykable({ location }) {
  return (
    <Layout pageTitle='Showroom' location={location}>
      <Router>
        <ShowRoom exact path='/showroom/' />
        <ShowroomPublic path='/showroom/:resykableGuid' />
      </Router>
    </Layout>
  )
}
