import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 40px 0;
`

const Avatar = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.primary};
`

const Name = styled.h5`
  font-family: QuickSand;
  font-size: 21px;
  color: ${({ theme }) => theme.colors.primary};

  margin: 8px 0;
`

const Button = styled.button`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.gray};
`

const User = ({ user, viewOtherItems }) => {
  return (
    <Wrapper>
      <Avatar src={user.avatar} alt={user.name} />
      <Name>{user.name}</Name>
      {/* <Button type='button' onClick={viewOtherItems}>
        View other items
      </Button> */}
    </Wrapper>
  )
}

export default User
